import * as Util from '../helpers/util';

import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    ChartPieIcon,
    QuestionMarkCircleIcon,
    DocumentTextIcon,
    BuildingOfficeIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    TagIcon,
    BoltIcon,
    CpuChipIcon, ServerIcon,
} from '@heroicons/react/24/outline'
import { Dropdown, DropdownItem } from "./dropdown";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";
import { useStore } from "../store";


const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, newTab: false },
    { name: 'Activity', href: '/activity', icon: BoltIcon, newTab: false },
    { name: 'Anchors', href: '/anchors', icon: DocumentTextIcon, newTab: false },
    { name: 'Tags', href: '/tags', icon: TagIcon, newTab: false },
    //{ name: 'Team', href: '/team', icon: UsersIcon },
    { name: 'Health', href: '/health', icon: CpuChipIcon, newTab: false },
    { name: 'Reports', href: '/api/report/weekly/', icon: ChartPieIcon, newTab: true },
    { name: 'GDrive', href: '/gdrive', icon: ServerIcon, newTab: false },
]

export const Sidebar = (props) => {
    const { hidden, sidebarOpen, showContext, onSidebarOpen, onTutorial, showToast } = props

    const { usr_info, setUsrInfo } = useStore(x => x)
    const navigate = useNavigate()

    /*
    const safe_government = (governments.length > 0)? governments[0]: { uid: '', name: 'Empty' } // This is seperated because if its not, the || always fails
    const government = governments.find( x => x.uid === government_uid ) || safe_government
    const safe_policy = (policies.length > 0)? policies[0]: { uid: '', name: 'Empty' } // This is seperated because if its not, the || always fails
    const policy = policies.find( x => x.uid === policy_uid ) || safe_policy
     */

    const calculateContext = () => {
        const path = `${window.location.pathname}`.replace(/^\//, '').replace(/\/.*$/, '').toLowerCase()
        const idx = navigation.findIndex( x => x.href === `/${path}` )
        return Math.max(0, idx)
    }

    const [state, setState] = useState({
        current_idx: calculateContext(),
    })
    const { current_idx } = state

    const contexts = []
    /*
    const contexts = [
        {
            name: 'Government',
            header: 'Select Government',
            id: 'government_uid',
            value: government.name,
            items: governments,
            onClick: onGovernmentChange,
        },
        {
            name: 'Policies',
            header: 'Select Policy',
            id: 'policy_uid',
            value: policy.name,
            items: policies,
            onClick: onPolicyChange,
        },
    ].filter( x => showContext[x.id] )
    */

    const linkContext = (href) => {
        return href.replace("tenant_uid", usr_info?.tenant?.uid)
    }

    const handleClick = (idx) => {
        if (navigation[idx].newTab) {
            window.open(linkContext(navigation[idx].href), '_blank')
        }
        else {
            setState(prev => ({ ...prev, current_idx: idx }))
        }
    }

    const handleTutorial = () => {
        Util.fetch_js('/api/human/client_settings/', { tutorial: true },
            js => {
                setUsrInfo(js.human)
            }, showToast )

        onTutorial()
    }

    return (
        <div className={Util.classNames((hidden)? "hidden": "")}>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-30 lg:hidden" onClose={onSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => onSidebarOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <img
                                            className="h-8 w-auto"
                                            src={Logo}
                                            alt="Your Company"
                                        />
                                    </div>
                                    <nav className="flex flex-1 flex-col">
                                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                                            <li>
                                                <ul role="list" className="-mx-2 space-y-1">
                                                    {navigation.map((item, idx) => (
                                                        <li key={`${item.name}_${idx}`}>
                                                            <Link to={linkContext(item.href)}
                                                                onClick={() => handleClick(idx)}
                                                                className={Util.classNames(
                                                                    (current_idx === idx)
                                                                        ? 'bg-gray-50 text-indigo-600'
                                                                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                )}>
                                                                <item.icon
                                                                    className={Util.classNames(
                                                                        (current_idx === idx) ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                                        'h-6 w-6 shrink-0'
                                                                    )}
                                                                    aria-hidden="true"
                                                                />
                                                                {item.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            {contexts.length > 0 &&
                                            <li>
                                                <div className="text-xs font-semibold leading-6 text-gray-400">Context</div>
                                                <ul role="list" className="-mx-2 mt-2 space-y-1">
                                                    {contexts.filter(x => showContext[x.id]).map((context, idx) => (
                                                        <li key={`context_${idx}`}>
                                                            <div className={Util.classNames(
                                                                false
                                                                    ? 'bg-gray-50 text-indigo-600'
                                                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                                                )}>
                                                                <span className={Util.classNames(
                                                                    false
                                                                        ? 'text-indigo-600 border-indigo-600'
                                                                        : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                                                    'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                                                )}>
                                                                    {context.name[0]}
                                                                </span>
                                                                <Dropdown
                                                                    header={context.header}
                                                                    value={context.value}
                                                                    align="left">
                                                                    {context.items.map( (item, idx) => (
                                                                        <DropdownItem
                                                                            id={context.id}
                                                                            key={`${context.id}_${idx}`}
                                                                            value={item.uid}
                                                                            onClick={context.onClick}>
                                                                            {item.name}
                                                                        </DropdownItem>
                                                                    ))}
                                                                </Dropdown>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            }
                                            <li className="mt-auto">
                                                <div className={Util.classNames("cursor-pointer group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                                                                (!usr_info.client_settings.tutorial)? 'animate-bounce': '')}
                                                     onClick={handleTutorial}>
                                                    <QuestionMarkCircleIcon
                                                        className="h-6 w-6 shrink-0 text-indigo-600 group-hover:text-indigo-500"
                                                        aria-hidden="true"
                                                    />
                                                    Tutorial
                                                </div>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-30 lg:flex lg:w-52 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                        <img
                            className="h-8 w-auto"
                            src={Logo}
                            alt="Your Company"
                        />
                    </div>
                    <nav className="flex flex-1 flex-col">
                        <ul role="list" className="flex flex-1 flex-col gap-y-7">
                            <li>
                                <ul role="list" className="-mx-2 space-y-1">
                                    {navigation.map((item, idx) => (
                                        <li key={`${item.name}_${idx}`}>
                                            <Link to={linkContext(item.href)}
                                                onClick={() => handleClick(idx)}
                                               className={Util.classNames(
                                                   (current_idx === idx)
                                                       ? 'bg-gray-50 text-indigo-600'
                                                       : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                   'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                               )}>
                                                <item.icon
                                                    className={Util.classNames(
                                                        (current_idx === idx) ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                                                        'h-6 w-6 shrink-0'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            {contexts.length > 0 &&
                            <li>
                                <div className="text-xs font-semibold leading-6 text-gray-400">Context</div>
                                <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {contexts.map((context, idx) => (
                                    <li key={`context_${idx}`}>
                                        <div className={Util.classNames(
                                                false
                                                    ? 'bg-gray-50 text-indigo-600'
                                                    : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}>
                                            <span className={Util.classNames(
                                                false
                                                    ? 'text-indigo-600 border-indigo-600'
                                                    : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                                'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                            )}>
                                                {context.name[0]}
                                            </span>
                                            <Dropdown
                                                header={context.header}
                                                value={context.value}
                                                align="left">
                                                {context.items.map( (item, idx) => (
                                                    <DropdownItem
                                                        id={context.id}
                                                        key={`${context.id}_${idx}`}
                                                        value={item.uid}
                                                        onClick={context.onClick}>
                                                        {item.name}
                                                    </DropdownItem>
                                                ))}
                                            </Dropdown>
                                        </div>
                                    </li>
                                ))}
                                </ul>
                            </li>
                            }
                            <li className="mt-auto">
                                <div className={Util.classNames("cursor-pointer group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                                                (!usr_info.client_settings.tutorial)? 'animate-bounce': '')}
                                     onClick={handleTutorial}>
                                    <QuestionMarkCircleIcon
                                        className="h-6 w-6 shrink-0 text-indigo-600 group-hover:text-indigo-500"
                                        aria-hidden="true"
                                    />
                                    Tutorial
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}
